import {BackendService} from './backend-service';
import {EnvironmentService} from './environment-service';
import {RestClient} from './util/rest-client';
import {AssistentenService} from './assistenten-service';
import {VbAssistentZuordnungService} from './vb-assistent-zuordnung-service';
import {ProtocolService} from './protocol-service';
import {Authenticator} from './authentication/authenticator';
import {AzureLogging} from './app-insights-service';
import {GvaService} from './gva-service';
import {AddressValidatorService} from './address-validator-service';
import { SonderentscheidService } from './sonderentscheid-service';

export class AsysService {

    backend: BackendService;
    addressValidator: AddressValidatorService;
    azureLogging:AzureLogging;
    assistenten: AssistentenService;
    vbAssistentZuordnung: VbAssistentZuordnungService;
    protocol: ProtocolService;
    gva: GvaService;
    sonderentscheid: SonderentscheidService;

    constructor(public authenticator: Authenticator, public environment: EnvironmentService = new EnvironmentService()) {
    }

    async initialize() {
        const backendUrl = await this.environment.backendUrl();

        try {
            this.azureLogging = await AzureLogging.initializeFromEnvironmentConfiguration(this.environment);
            this.azureLogging.infoTrace('Azure Insights initialized', this.authenticator);
        } catch (_) {
            console.error('Azure Insights could not be initialized');
        }

        const restClient = new RestClient(backendUrl, this.authenticator);

        this.backend = new BackendService(restClient);
        this.addressValidator = new AddressValidatorService(restClient);
        this.assistenten = new AssistentenService(restClient);
        this.vbAssistentZuordnung = new VbAssistentZuordnungService(restClient);
        this.protocol = new ProtocolService(restClient);
        this.gva = new GvaService(restClient);
        this.sonderentscheid = new SonderentscheidService(restClient);
    }
}

export enum DvagPermission {
    AppVb = 'permission.assistent.app.vb',
    AppSb = 'permission.assistent.app.sb',
    Anlegen = 'permission.assistent.anlegen',
    Anzeigen = 'permission.assistent.anzeigen',
    AuflistenEigene = 'permission.assistent.auflisten.eigene',
    AuflistenAlle = 'permission.assistent.auflisten.alle',
    Bestaetigen = 'permission.assistent.bestaetigen',
    DatenKontaktAendern = 'permission.assistent.daten.kontakt.aendern',
    DatenPersoenlichAendern = 'permission.assistent.daten.persoenlich.aendern',
    Kuendigen = 'permission.assistent.kuendigen',
    KuendigenGesellschaft = 'permission.assistent.kuendigen.gesellschaft',
    Loeschen = 'permission.assistent.loeschen',
    Protokoll = 'permission.assistent.protokoll',
    QS = 'permission.assistent.app.qs',
    DEV = 'permission.assistent.app.dev',
    GvaAnlegen = 'permission.assistent.gva.anlegen',
    GvaDatenPersoenlichAendern = 'permission.assistent.gva.daten.persoenlich.aendern',
    GvaDatenKontaktAendern = 'permission.assistent.gva.daten.kontakt.aendern',
    GvaKuendigen = 'permission.assistent.gva.kuendigen',
    SonderentscheidAendern = 'permission.assistent.sonderentscheid.aendern'
}
